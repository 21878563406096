'use client';
import { useTranslations } from 'next-intl';

import { OperationModal } from '@/components/OperationModal';
import { Button } from '@/lib/io-kit/Button';
import { Heading } from '@/lib/io-kit/Heading';
import { useWalletConnectState } from '@/features/wallet-connect/context';
import { RequestMethodCard } from '@/features/web3-modals';

import styles from './UnsupportedMethodModal.module.scss';

export function UnsupportedMethodModal() {
  const t = useTranslations('Components.Web3Modals');

  const { modal, mutations } = useWalletConnectState();

  const requestEvent = modal.data?.requestEvent;
  const requestSession = modal.data?.requestSession;

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <div className={styles.text}>{t('Common.missingRequestData')}</div>;
  }

  // Get required request data
  const { params } = requestEvent;
  const { request } = params;

  function handleClose() {
    mutations.closeModal();
  }

  return (
    <OperationModal
      isOpen={modal.isOpen}
      largeIcon="WalletConnect"
      hideCloseButton
      heading={
        <Heading as="h2" variant="subheading1">
          {t('UnsupportedMethodModal.title')}
        </Heading>
      }
      description={<span className={styles.description}>{t('UnsupportedMethodModal.description')}</span>}
      footer={
        <Button as="button" variant="light" onClick={handleClose}>
          {t('Common.closeButton')}
        </Button>
      }
      // Closing is handled by the reducer
      onClose={() => {}}
    >
      <div>
        <div className={styles.divider}></div>
        <RequestMethodCard methods={[request.method]} />
      </div>
    </OperationModal>
  );
}

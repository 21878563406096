'use client';

import { useSyncExternalStore, Dispatch, useRef, useCallback } from 'react';
import { IWeb3Wallet } from '@walletconnect/web3wallet';
import { RELAYER_EVENTS } from '@walletconnect/core';
import { SignClientTypes } from '@walletconnect/types';
import { toast } from 'react-hot-toast';

import { enqueueRequest } from '@/lib/web3-access';
import {
  handleConnect,
  handleDisconnect,
  handleSessionProposal,
  handleSessionRequest,
  handleSessionDelete,
  handleSessionRefresh,
  handleAuthRequest,
} from '@/features/wallet-connect/services';
import { Actions } from '@/features/wallet-connect/context';

export function useWeb3WalletEvents(web3Wallet: IWeb3Wallet | null, dispatch: Dispatch<Actions>) {
  const disconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const sessionProposals = useRef<string[]>([]);
  const isToastVisibleRef = useRef<boolean>(false);
  const wasDisconnectedRef = useRef<boolean>(false);
  const disconnectTimeout = 10_000; // 10 seconds

  const subscribe = useCallback(() => {
    if (!web3Wallet) return () => {};

    const onConnect = () => {
      if (disconnectTimeoutRef.current) {
        clearTimeout(disconnectTimeoutRef.current);
        disconnectTimeoutRef.current = null;
      }
      handleConnect(dispatch, web3Wallet, isToastVisibleRef, wasDisconnectedRef);
    };

    const onDisconnect = () => {
      if (!disconnectTimeoutRef.current) {
        disconnectTimeoutRef.current = setTimeout(() => {
          handleDisconnect(dispatch, web3Wallet, isToastVisibleRef, wasDisconnectedRef);
        }, disconnectTimeout);
      }
    };

    const onSessionProposal = (proposal: SignClientTypes.EventArguments['session_proposal']) => {
      if (!sessionProposals.current.includes(String(proposal.id))) {
        sessionProposals.current.push(String(proposal.id));
        enqueueRequest(() => {
          handleSessionProposal(dispatch, proposal);
          return Promise.resolve();
        });
      }
    };

    const onSessionRequest = (request: SignClientTypes.EventArguments['session_request']) => {
      enqueueRequest(() => {
        handleSessionRequest(dispatch, web3Wallet, request);
        return Promise.resolve();
      });
    };

    const onAuthRequest = (request: any) => handleAuthRequest(dispatch, request);
    const onSessionDelete = (data: any) => handleSessionDelete(dispatch, web3Wallet, data);
    const onSessionRefresh = () => handleSessionRefresh(dispatch, web3Wallet);

    /******************************************************************************
     * Set up WalletConnect event listeners
     *****************************************************************************/
    // connection
    web3Wallet.core.relayer.on(RELAYER_EVENTS.connect, onConnect);
    web3Wallet.core.relayer.on(RELAYER_EVENTS.disconnect, onDisconnect);
    //sign
    web3Wallet.on('session_proposal', onSessionProposal);
    web3Wallet.on('session_request', onSessionRequest);
    web3Wallet.on('auth_request', onAuthRequest);
    // session delete
    web3Wallet.on('session_delete', onSessionDelete);

    // refresh sessions on init
    onSessionRefresh();

    return () => {
      if (disconnectTimeoutRef.current) {
        clearTimeout(disconnectTimeoutRef.current);
      }
      if (isToastVisibleRef.current) {
        toast.dismiss();
        isToastVisibleRef.current = false;
      }

      // Cleanup function to remove event listeners.
      web3Wallet.core.relayer.off(RELAYER_EVENTS.connect, onConnect);
      web3Wallet.core.relayer.off(RELAYER_EVENTS.disconnect, onDisconnect);
      web3Wallet.off('session_proposal', onSessionProposal);
      web3Wallet.off('session_request', onSessionRequest);
      web3Wallet.off('auth_request', onAuthRequest);
      web3Wallet.off('session_delete', onSessionDelete);
    };
  }, [web3Wallet, dispatch]);

  useSyncExternalStore(
    subscribe,
    () => {}, // This is a no-op snapshot function as the state updates are handled through dispatch
    () => {}, // This is a no-op server function as this hook is client-only
  );
}

'use client';

import { useCallback, useState } from 'react';
import { getSdkError } from '@walletconnect/utils';
import { useTranslations } from 'next-intl';
import cx from 'classnames';

import { showDismissableAlertToast } from '@/components/Toasts';
import { useWalletConnectState } from '@/features/wallet-connect/context';
import { eip155Wallets } from '@/lib/web3-access';
import { MemoDropdown } from '@/features/web3-modals';
import { WalletConnectModalWrapper } from '@/features/web3-modals/wallet-connect';

import styles from './AuthRequestModal.module.scss';

export function AuthRequestModal() {
  const t = useTranslations('Components.Web3Modals');
  const {
    web3Wallet,
    settings: {
      activeVault,
      addresses: { eip155Address },
    },
    modal,
    mutations,
  } = useWalletConnectState();

  const [memo, setMemo] = useState('');
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  // Get request and wallet data from modal state
  const request = modal.data?.request;

  const iss = request ? `did:pkh:eip155:1:${eip155Address}` : '';
  const message = request ? web3Wallet.formatMessage(request.params.cacaoPayload, iss) : '';

  // Handle approve action
  const onApprove = useCallback(async () => {
    if (request) {
      setIsLoadingApprove(true);
      try {
        mutations.openModal('SessionSubmittedModal', modal.data);
        const signature = await eip155Wallets.signMessage({ data: { vaultId: activeVault.id, memo }, message });
        await web3Wallet.respondAuthRequest(
          {
            id: request.id,
            signature: {
              s: signature,
              t: 'eip191',
            },
          },
          iss,
        );
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingApprove(false);
        mutations.closeModal();
      }
    }
  }, [request, mutations, modal.data, activeVault.id, memo, message, web3Wallet, iss]);

  // Handle reject action
  const onReject = useCallback(async () => {
    if (request) {
      setIsLoadingReject(true);
      try {
        await web3Wallet.respondAuthRequest(
          {
            id: request.id,
            error: getSdkError('USER_REJECTED'),
          },
          iss,
        );
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingReject(false);
        mutations.closeModal();
      }
    }
  }, [request, web3Wallet, iss, mutations]);

  // Ensure request and wallet are defined
  if (!request) {
    return <div className={styles.text}>{t('Common.missingRequestData')}</div>;
  }

  // Get required request data
  const { params } = request;
  const { metadata } = params.requester;

  return (
    <WalletConnectModalWrapper
      intention={t('SessionTypes.Auth.intention')}
      metadata={metadata}
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={{ active: isLoadingApprove }}
      rejectLoader={{ active: isLoadingReject }}
    >
      <div className={styles.row}>
        <div>
          <h5 className={styles.heading}>{t('Common.message')}</h5>
          <div className={cx(styles.text, styles.textLight)}>{message}</div>
        </div>
      </div>
      <div className={cx(styles.row, styles.memo)}>
        <MemoDropdown memo={memo} onMemoChange={setMemo} />
      </div>
    </WalletConnectModalWrapper>
  );
}

import { CoreTypes } from '@walletconnect/types';
import { useTranslations } from 'next-intl';

import { IconAsset } from '@/lib/io-kit/IconAssets';
import { Link } from '@/lib/navigation';
import { Button } from '@/lib/io-kit/Button';

import styles from './ThreatPrompt.module.scss';

type Props = {
  metadata: CoreTypes.Metadata;
  onApprove: () => void;
  onReject: () => void;
};

export function ThreatPrompt({ metadata, onApprove, onReject }: Props) {
  const t = useTranslations('Components.Web3Modals.Components.ThreatPrompt');

  const { url } = metadata;

  return (
    <div>
      <IconAsset.AlertCircleWithRippleEffect width={55} height={55} color="error" />
      <h3>{t('websiteFlagged')}</h3>
      <Link href={url} data-testid="session-info-card-url" target="_blank">
        {url}
      </Link>
      <div className={styles.divider}></div>
      <p>{t('warningMessage')}</p>
      <div className={styles.divider}></div>
      <div className={styles.row}>
        <Button onClick={onReject} data-testid="session-approve-button" variant="light">
          {t('close')}
        </Button>
        <Button onClick={onApprove} data-testid="session-reject-button" variant="danger">
          {t('proceedAnyway')}
        </Button>
      </div>
    </div>
  );
}

'use client';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { capitalize } from '@walletconnect/utils';

import { Alert } from '@/lib/io-kit/Alert';
import { Icons } from '@/lib/io-kit/Icons';
import { IconAsset } from '@/lib/io-kit/IconAssets';
import { LinkExternal } from '@/components/Link';
import { useWalletConnectState } from '@/features/wallet-connect/context';
import { SessionMetadata } from '@/lib/web3-access';

import styles from './ProjectInfoCard.module.scss';

type Props = {
  metadata: SessionMetadata;
  intention?: string;
};

export function ProjectInfoCard({ metadata, intention }: Props) {
  const t = useTranslations('Components.Web3Modals.Components');
  const { modal } = useWalletConnectState();
  const currentRequestVerifyContext = modal.data?.currentRequestVerifyContext;

  const validation = currentRequestVerifyContext?.verified.validation;
  const isScam = currentRequestVerifyContext?.verified.isScam;
  const isSessionProposal = modal.view === 'SessionProposalModal';

  const { url, icons, name } = metadata;

  const hasIcons = icons && icons.length > 0;

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <h1>{capitalize(intention ?? t('ProjectInfoCard.connect'))}</h1>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.iconsContainer}>
          {hasIcons && (
            <>
              {isSessionProposal ? (
                <IconAsset.WalletConnect width={50} height={50} className={styles.icon} />
              ) : (
                <>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img src={icons?.[0]} alt={`${name} icon`} width={40} height={40} className={styles.icon} />
                </>
              )}
              <Icons.LinkRequest width={20} height={20} className={styles.icon} />
            </>
          )}
          <Image
            width={32}
            height={32}
            className={styles.icon}
            src="/vault-with-iofinnet-logo.svg"
            alt="io.finnet logo"
          />
        </div>
        <p className={styles.description}>
          {t('ProjectInfoCard.requestGenerated')}
          <LinkExternal variant="underline" href={url}>
            {new URL(url ?? '')?.hostname}
          </LinkExternal>
          {validation === 'VALID' && (
            <Image width={16} height={16} className={styles.icon} src="/verified-domain.svg" alt="Verified Domain" />
          )}
        </p>
      </div>
      <div className={styles.cardFooter}>
        {isScam && <Alert title={t('ProjectInfoCard.potentialThreat')} variant="error" inline />}
        {validation === 'INVALID' && <Alert title={t('ProjectInfoCard.invalidDomain')} variant="warning" inline />}
      </div>
    </div>
  );
}

import { IWeb3Wallet } from '@walletconnect/web3wallet';

import { State } from '@/features/wallet-connect/context';

export const WALLET_CONNECT_WEBSITE = 'https://walletconnect.com';

export const defaultChainId = 'eip155:1';
export const defaultVault = {
  id: '',
  name: '',
};

export const defaultWalletConnectSettings = {
  activeChainId: defaultChainId,
  activeVault: defaultVault,
  addresses: {
    eip155Address: '',
  },
  sessions: [],
  // true if there is an active session through web3wallet
  isUserConnected: false,
  // true if user click on logout button
  isUserDisconnect: false,
};

export const initialState: State = {
  isLoading: true,
  settings: defaultWalletConnectSettings,
  web3Wallet: {} as IWeb3Wallet,
  modal: {
    isOpen: false,
    view: undefined,
    data: undefined,
  },
  sidebar: {
    isOpen: false,
  },
};

import { useTranslations } from 'next-intl';
import cx from 'classnames';

import { Icons } from '@/lib/io-kit/Icons';
import { IconAsset } from '@/lib/io-kit/IconAssets';
import { useWalletConnectState } from '@/features/wallet-connect/context';

import styles from './VerifyInfoBox.module.scss';

export function VerifyInfoBox() {
  const t = useTranslations('Components.Web3Modals.Components.VerifyInfoBox');

  const { modal } = useWalletConnectState();
  const currentRequestVerifyContext = modal.data?.currentRequestVerifyContext;

  const validation = currentRequestVerifyContext?.verified.validation;
  const isScam = currentRequestVerifyContext?.verified.isScam;
  const isLegit = validation === 'VALID' && !isScam;

  // Do not show the info box if the validation is unknown
  if (validation === 'UNKNOWN') return null;

  return (
    <div className={styles.container}>
      <div className={styles.divider} />
      {isScam && (
        <div className={cx(styles.card, styles.error)}>
          <IconAsset.AlertCircleWithRippleEffect className={cx(styles.icon, styles.iconError)} />
          <p>
            <span className={styles.text}>{t('knownSecurityRisk')}</span>
            <span>{t('knownSecurityRiskDescription')}</span>
          </p>
        </div>
      )}
      {validation === 'INVALID' && (
        <div className={cx(styles.card, styles.warning)}>
          <Icons.AlertCircle className={cx(styles.icon, styles.iconWarning)} />
          <p>
            <span className={styles.text}>{t('domainMismatch')}</span>
            <span>{t('domainMismatchDescription')}</span>
          </p>
        </div>
      )}
      {isLegit && (
        <div className={cx(styles.card, styles.warning)}>
          <Icons.AlertCircle className={cx(styles.icon, styles.iconWarning)} />
          <p>
            <span>{t('thirdPartyWarningMessage')}</span>
          </p>
        </div>
      )}
    </div>
  );
}

'use client';

import { useEffect, useMemo, ReactNode } from 'react';

import { useWalletConnectState } from '@/features/wallet-connect/context';
import { SessionSubmittedModal } from '@/features/web3-modals/shared';
import {
  AuthRequestModal,
  SendTransactionModal,
  SessionProposalModal,
  SignMessageModal,
  SignTypedDataModal,
  UnsupportedMethodModal,
} from '@/features/web3-modals/wallet-connect';

import styles from './ModalProvider.module.scss';

type WalletConnectModalProviderProps = {
  children: ReactNode;
};

export function WalletConnectModalProvider({ children }: WalletConnectModalProviderProps) {
  const { modal, sidebar, settings, mutations } = useWalletConnectState();
  const { isOpen, view } = modal;

  const componentView = useMemo(() => {
    if (!view) return null;
    const ModalComponent = {
      AuthRequestModal: <AuthRequestModal />,
      SessionProposalModal: <SessionProposalModal />,
      SendTransactionModal: <SendTransactionModal />,
      SignMessageModal: <SignMessageModal />,
      SignTypedDataModal: <SignTypedDataModal />,
      UnsupportedMethodModal: <UnsupportedMethodModal />,
      SessionSubmittedModal: (
        <SessionSubmittedModal isOpen={isOpen} vaultId={settings.activeVault.id} closeModal={mutations.closeModal} />
      ),
    }[view];

    return ModalComponent;
  }, [view, isOpen, settings.activeVault.id, mutations.closeModal]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.withModalOpen);
      // Auto closing sidebar on open modal to avoid scroll issue on modal content
      if (view !== 'SessionProposalModal' && sidebar.isOpen) mutations.toggleSidebar();
    } else document.body.classList.remove(styles.withModalOpen);
  }, [isOpen, view, sidebar.isOpen, mutations]);

  return (
    <>
      {children}
      {isOpen && componentView}
    </>
  );
}

'use client';
import { useTranslations } from 'next-intl';

import styles from './RequestMethodCard.module.scss';

type Props = {
  methods: string[];
};

export function RequestMethodCard({ methods }: Props) {
  const t = useTranslations('Components.Web3Modals.Components.RequestMethodCard');

  return (
    <div className={styles.row}>
      <h5 className={styles.heading}>{t('methods')}</h5>
      <p className={styles.text} data-testid="request-methods">
        {methods.join(', ')}
      </p>
    </div>
  );
}

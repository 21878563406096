'use client';
import { useCallback, useState } from 'react';
import { useTranslations } from 'next-intl';
import { RequestData } from '@iofinnet/io-web3-provider';

import { showDismissableAlertToast } from '@/components/Toasts';
import { useWalletConnectState } from '@/features/wallet-connect/context';
import {
  approveEIP155Request,
  rejectEIP155Request,
  adaptToGenericMessage,
  RequestParams,
  SessionMetadata,
} from '@/lib/web3-access';
import { MemoDropdown, RequestDataCard } from '@/features/web3-modals';
import { WalletConnectModalWrapper } from '@/features/web3-modals/wallet-connect';

import styles from './SendTransactionModal.module.scss';

export function SendTransactionModal() {
  const t = useTranslations('Components.Web3Modals');

  const { web3Wallet, modal, mutations, settings } = useWalletConnectState();

  const requestEvent = modal.data?.requestEvent;
  const requestSession = modal.data?.requestSession;
  const [memo, setMemo] = useState('');
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  // Handle approve action
  const onApprove = useCallback(async () => {
    if (requestEvent && requestSession) {
      setIsLoadingApprove(true);
      try {
        mutations.openModal('SessionSubmittedModal', modal.data);

        const requestParams: RequestParams = {
          id: requestEvent.id,
          chainId: requestEvent.params.chainId,
          method: requestEvent.params.request.method,
          params: requestEvent.params.request.params,
        };

        const sessionMetadata: SessionMetadata = {
          url: requestSession.peer.metadata.url,
          name: requestSession.peer.metadata.name,
          description: requestSession.peer.metadata.description,
          icons: requestSession.peer.metadata.icons,
        };

        const data: RequestData = {
          vaultId: settings.activeVault.id,
          memo,
        };

        const response = await approveEIP155Request({
          requestParams,
          sessionMetadata,
          data,
        });

        await web3Wallet.respondSessionRequest({
          topic: requestEvent.topic,
          response,
        });
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingApprove(false);
        mutations.closeModal();
      }
    }
  }, [requestEvent, mutations, modal.data, settings.activeVault.id, requestSession, memo, web3Wallet]);

  // Handle reject action
  const onReject = useCallback(async () => {
    if (requestEvent) {
      setIsLoadingReject(true);
      const response = rejectEIP155Request(requestEvent.id);
      try {
        await web3Wallet.respondSessionRequest({
          topic: requestEvent.topic,
          response,
        });
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingReject(false);
        mutations.closeModal();
      }
    }
  }, [requestEvent, web3Wallet, mutations]);

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <div className={styles.text}>{t('Common.requestNotFound')}</div>;
  }

  // Get required request data
  const { params } = requestEvent;
  const { request, chainId } = params;
  const {
    peer: { metadata },
  } = requestSession;
  const { name, url } = metadata;

  const genericMessage = adaptToGenericMessage(request.method, request?.params, {
    chainId,
    dappName: name,
    dappSite: new URL(url).hostname,
    vaultName: settings.activeVault.name,
  });

  return (
    <WalletConnectModalWrapper
      intention={t('SessionTypes.SendTransaction.intention')}
      metadata={metadata}
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={{ active: isLoadingApprove }}
      rejectLoader={{ active: isLoadingReject }}
    >
      <RequestDataCard message={genericMessage} />
      <MemoDropdown memo={memo} onMemoChange={setMemo} />
    </WalletConnectModalWrapper>
  );
}

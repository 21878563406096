'use client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { buildApprovedNamespaces, getSdkError } from '@walletconnect/utils';
import { SignClientTypes } from '@walletconnect/types';
import { useTranslations } from 'next-intl';

import { showDismissableAlertToast } from '@/components/Toasts';
import { Icons } from '@/lib/io-kit/Icons';
import { EIP155_CHAINS, EIP155_SIGNING_METHODS, getChainData } from '@/lib/web3-access';
import { useWalletConnectState } from '@/features/wallet-connect/context';
import { initialState } from '@/features/wallet-connect/utils';
import { updateWeb3WalletSessions } from '@/features/wallet-connect/services';
import { VaultInfo } from '@/features/web3-modals';
import { WalletConnectModalWrapper } from '@/features/web3-modals/wallet-connect';

import styles from './SessionProposalModal.module.scss';

export function SessionProposalModal() {
  const t = useTranslations('Components.Web3Modals');

  const {
    web3Wallet,
    settings: {
      addresses: { eip155Address },
      activeVault,
    },
    modal,
    mutations,
  } = useWalletConnectState();

  const proposal = modal.data?.proposal as SignClientTypes.EventArguments['session_proposal'];
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const hasViewChanged = useRef(false);

  const supportedNamespaces = useMemo(() => {
    // eip155
    const eip155Chains = Object.keys(EIP155_CHAINS);
    const eip155Methods = Object.values(EIP155_SIGNING_METHODS);

    return {
      eip155: {
        chains: eip155Chains,
        methods: eip155Methods,
        events: ['accountsChanged', 'chainChanged'],
        accounts: eip155Chains.flatMap((chain) => `${chain}:${eip155Address}`),
      },
    };
  }, [eip155Address]);

  const requestedChains = useMemo(() => {
    if (!proposal) return [];
    const required = [];
    for (const [key, values] of Object.entries(proposal.params.requiredNamespaces)) {
      const chains = key.includes(':') ? key : values.chains;
      required.push(chains);
    }

    const optional = [];
    for (const [key, values] of Object.entries(proposal.params.optionalNamespaces)) {
      const chains = key.includes(':') ? key : values.chains;
      optional.push(chains);
    }

    // directly allow usage of both required and optional chains
    return [...new Set([...required.flat(), ...optional.flat()])];
  }, [proposal]);

  // the chains that are supported by the wallet from the proposal
  const supportedChains = useMemo(
    () =>
      requestedChains
        .map((chain) => {
          const chainData = getChainData(chain!);
          if (!chainData) return null;

          return chainData;
        })
        .filter(Boolean),
    [requestedChains],
  );

  // get required chains that are not supported by the wallet
  const notSupportedChains = useMemo(() => {
    if (!proposal) return [];
    const required = [];
    for (const [key, values] of Object.entries(proposal.params.requiredNamespaces)) {
      const chains = key.includes(':') ? key : values.chains;
      required.push(chains);
    }
    return required
      .flat()
      .filter(
        (chain) =>
          !supportedChains
            .map((supportedChain) => `${supportedChain?.namespace}:${supportedChain?.chainId}`)
            .includes(chain!),
      )
      .filter(Boolean);
  }, [proposal, supportedChains]);

  const namespaces = useMemo(() => {
    if (!proposal) return;

    try {
      // the builder throws an exception if required namespaces are not supported
      return buildApprovedNamespaces({
        proposal: proposal.params,
        supportedNamespaces,
      });
    } catch {
      //
    }
  }, [proposal, supportedNamespaces]);

  useEffect(() => {
    if (modal.view !== 'SessionProposalModal') hasViewChanged.current = true;
  }, [modal.view, hasViewChanged]);

  // handle approve action, construct session namespace
  const onApprove = useCallback(async () => {
    if (proposal && namespaces) {
      setIsLoadingApprove(true);

      try {
        await web3Wallet.approveSession({
          id: proposal.id,
          namespaces,
        });
        mutations.setSettings({
          sessions: Object.values(web3Wallet.getActiveSessions()),
        });

        // Extract the active network and chain ID from the approved namespaces
        const activeChainId = requestedChains[0];

        if (activeChainId) {
          // Update settings with the active network and chain ID
          await new Promise((resolve) => setTimeout(resolve, 1000));
          await updateWeb3WalletSessions(web3Wallet, activeChainId, eip155Address);

          mutations.setSettings({
            addresses: { eip155Address },
            activeVault,
            activeChainId,
          });
        }
      } catch (err) {
        setIsLoadingApprove(false);
        // TODO handle translation with 'Toasts.WalletConnectToasts.ApproveSessionError'
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingApprove(false);
        // Only close modal if view has not changed
        if (!hasViewChanged.current) mutations.closeModal();
      }
    }
  }, [activeVault, eip155Address, mutations, namespaces, proposal, requestedChains, web3Wallet]);

  // Handle reject action
  const onReject = useCallback(async () => {
    if (proposal) {
      try {
        setIsLoadingReject(true);
        await web3Wallet.rejectSession({
          id: proposal.id,
          reason: getSdkError('USER_REJECTED_METHODS'),
        });
      } catch (err) {
        // TODO handle translation with 'Toasts.WalletConnectToasts.RejectSessionError'
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingReject(false);
        mutations.setSettings(initialState.settings);
        mutations.closeModal();
      }
    }
  }, [mutations, proposal, web3Wallet]);

  // Commenting this while RANGER-1397 has ongoing discussion on how to improve the design
  // @see {@link https://iofinnet.atlassian.net/browse/RANGER-1397}

  // const networks = useMemo(() => {
  //   return supportedChains
  //     .map((chain) => {
  //       if (!chain) return null;
  //       return chain.name;
  //     })
  //     .filter((network): network is Network.Type => network !== null);
  // }, [supportedChains]);

  // const requiredNetwork = useMemo(() => {
  //   for (const chainId of requestedChains) {
  //     const chainData = getChainData(chainId);
  //     if (chainData) {
  //       return chainData;
  //     }
  //   }
  //   return;
  // }, [requestedChains]);

  return (
    <WalletConnectModalWrapper
      metadata={proposal.params.proposer.metadata}
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={{ active: isLoadingApprove }}
      rejectLoader={{ active: isLoadingReject }}
      infoBoxCondition={notSupportedChains.length > 0 || supportedChains.length === 0}
      disableApprove={notSupportedChains.length > 0 || supportedChains.length === 0}
      infoBoxText={t('UnsupportedMethodModal.sessionCannotBeApproved')}
      intention={t('SessionTypes.SessionProposal.intention')}
    >
      <div className={styles.column}>
        <VaultInfo vaultId={activeVault.id} />
      </div>
      {/* 
      <div className={styles.column}>
        <SelectNetwork networks={networks} network={requiredNetwork?.type} />
      </div> 
      */}
      <div className={styles.row}>
        <h4 className={styles.heading}>{t('SessionTypes.SessionProposal.requestedPermissions')}</h4>
      </div>
      <div className={styles.row}>
        <div>
          <Icons.SuccessCircle color="green" width={16} height={16} className={styles.icon} />
          <span className={styles.text}>{t('SessionTypes.SessionProposal.viewBalanceActivity')}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <Icons.SuccessCircle color="green" width={16} height={16} className={styles.icon} />
          <span className={styles.text}>{t('SessionTypes.SessionProposal.sendApprovalRequests')}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <Icons.Close width={16} height={16} className={styles.icon} />
          <span className={styles.text}>{t('SessionTypes.SessionProposal.moveFundsWithoutPermission')}</span>
        </div>
      </div>
    </WalletConnectModalWrapper>
  );
}

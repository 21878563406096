'use client';
import { useTranslations } from 'next-intl';
import { useEffect, useMemo, useState } from 'react';

import { DetailsCard } from '@/components/DetailsCard';
import { CryptoAmount } from '@/components/CryptoAmount';
import { Heading } from '@/lib/io-kit/Heading';
import { Icons } from '@/lib/io-kit/Icons';
import { useFormatter } from '@/lib/intl';
import { GenericMessage, Signatures, extractFields } from '@/lib/web3-access';
import { convertWeiToEther } from '@/lib/web3-utils';

import styles from './RequestDataCard.module.scss';
import { DetailsDataCard } from './DetailsDataCard';

type Props = {
  message: GenericMessage;
};

export function RequestDataCard({ message }: Props) {
  const t = useTranslations('Components.Web3Modals.Components.RequestDataCard');
  const formatter = useFormatter();

  const displayOrder = [
    'site',
    'network',
    'method',
    'primaryType',
    'token',
    'from',
    'to',
    'spender',
    'amount',
    'value',
    'gas',
    'sigDeadline',
  ];

  const [details, setDetails] = useState(() =>
    extractFields(message, displayOrder).map((detail) => ({
      key: detail.key,
      label: t(`${detail.key}` as any),
      value: <DetailsDataCard detailKey={detail.key} value={detail.value} message={message} />,
    })),
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = message.message.find((msg) => msg.title === 'data')?.description;
      if (!data) return;

      const callMethodCode = data.slice(0, 10);
      const fetchedMethodCode = await Signatures.fetchSignatureMethod(callMethodCode);

      setDetails((prev) =>
        prev.map((item) =>
          item.key === 'method'
            ? {
                ...item,
                value: <DetailsDataCard detailKey="method" value={fetchedMethodCode} message={message} />,
              }
            : item,
        ),
      );
    };

    fetchData();
  }, [message, t]);

  const totalAmount = useMemo(() => {
    const amount = message.message.find((msg) => msg.title === 'amount')?.description ?? '0';
    const value = message.message.find((msg) => msg.title === 'value')?.description ?? '0';
    const gas = message.message.find((msg) => msg.title === 'gas')?.description ?? '0';

    const amountInEther = convertWeiToEther(amount);
    const valueInEther = convertWeiToEther(value);
    const gasInEther = convertWeiToEther(gas);

    const total = amountInEther.plus(valueInEther).plus(gasInEther).toFixed(6);

    return formatter.cryptoFull({ value: total });
  }, [formatter, message.message]);

  return (
    <DetailsCard.Section heading={<DetailsTitle />} className={styles.container}>
      <DetailsCard.ValuesList data={details} />
      <div className={styles.divider} />
      <Total
        totalAmount={totalAmount}
        currency={message.message.find((msg) => msg.title === 'currency')?.description}
      />
    </DetailsCard.Section>
  );
}

function DetailsTitle() {
  const t = useTranslations('Components.Web3Modals.Components.RequestDataCard');
  return (
    <Heading as="h3" variant="heading6" className={styles.heading}>
      <Icons.ThirdParty className={styles.icon} />
      {t('title')}
    </Heading>
  );
}

function Total({ totalAmount, currency }: { totalAmount: string; currency: string | undefined }) {
  const t = useTranslations('Components.Web3Modals.Components.RequestDataCard');
  const data = [
    {
      label: t('totalAmount'),
      value: <TotalGroup totalCrypto={totalAmount} cryptoCurrency={currency} />,
    },
  ];

  return (
    <DetailsCard.Section className={styles.total}>
      <DetailsCard.ValuesList data={data} />
    </DetailsCard.Section>
  );
}

function TotalGroup({
  totalCrypto,
  cryptoCurrency,
  totalFiat,
  fiatCurrency = 'USD',
}: {
  totalCrypto: string;
  cryptoCurrency: string | undefined;
  totalFiat?: string;
  fiatCurrency?: string;
}) {
  const formatter = useFormatter();

  return (
    <div className={styles.totalGroup}>
      <CryptoAmount amount={totalCrypto} className={styles.amountOriginal} currency={cryptoCurrency} />
      {totalFiat && (
        <div className={styles.amountUsd}>{formatter.fiat({ value: totalFiat, currency: fiatCurrency })}</div>
      )}
    </div>
  );
}
